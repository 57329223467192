import React from "react"
import { Container,Row,Col } from 'react-bootstrap'
import Logo from "../images/logo2.inline.svg";

const Footer = () => {
  return (
    <Row className="footer">
        <Container>
            <div className="footer__widgets">
                <Row>
                    <Col lg={12}>
                        <div className="footer-bottom">
                            <div className="footer-logo">
                                <Logo/>
                            </div>
                            <p>
                                <a href="mailto:support@verifyonce.com">support@verifyonce.com</a>
                            </p>
                            <p>Copyright 2022 © Verify Once</p>
                        </div>
                    </Col>
                </Row>
            </div>
        </Container>
    </Row>
  )
}
export default Footer